import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import image from ".//Image/GST PANCHAYAT LOGO FINAL 2.png";
import axios from 'axios';
import telephone from './Image/telephone.png';
import baseUrl from './CONSTANT';

export default function Login() {
    const navigate = useNavigate()
    const [message, setMessage] = useState([]);
    const [color, setColor] = useState();
    const [otpstate, setOtpstate] = useState('none');

    function submitHandler(e) {
        e.preventDefault()
        if (e.target.logemail.value && e.target.logpass.value) {
            const phone_number = e.target.logemail.value;
            const otp = e.target.logpass.value;
            axios.post(`${baseUrl}/accounts/verify-otp/`, {
                phone_number, otp
            })
                .then((response) => {
                    setMessage(response.data.message)
                    if (response.data.status === 200) {
                        setColor("green");
                        localStorage.setItem('token', JSON.stringify(response.data.access));
                        navigate('/dashboard')
                        
                    } else {
                        setColor("red")
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else if (e.target.logemail.value) {
            const phone_number = e.target.logemail.value;
            axios.post(`${baseUrl}/accounts/user-login/`, {
                phone_number
            })
                .then((response) => {
                    setMessage(response.data.message)
                    if (response.data.status === 200) {
                        setOtpstate('flex')
                        setColor("green");
        
                    } else {
                        setColor("red")    
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else if (e.target.logpass.value) {
        }
        else {
            setMessage("Please Inter Your Phone Number")
            setColor("red")
            
        };
    }

    return (
        <div className='form-wraper'>
            <div className="cardlog">
                <div className='img-wraper'>
                    <img src={image} alt="this is logo" />
                </div>
                <h4 className="title">Log In!</h4>
                <h1 className="message-handle" style={{ backgroundColor: `${color}`, color: 'white', fontSize:"0.9rem" }}>{message}</h1>
                <form onSubmit={submitHandler} >
                    <div className="field">
                        <svg className="input-icon">
                        <img src={telephone} alt='telephone'/>
                        </svg>
                        <input autoComplete="off" placeholder="Phone Number" className="input-field" name="logemail" type="tel" />
                    </div>
                    <div className="field" style={{ display: `${otpstate}` }}>
                        <svg className="input-icon" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                            <path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z"></path></svg>
                        <input autoComplete="off" placeholder="OTP" className="input-field" name="logpass" type="password" />
                    </div>
                    <button className='btnlog' type="submit">Login</button>


                </form>
                <div className="p-container">
                    <p>Don’t Have an Account?</p>
                </div>
                <p className='Register-container'><Link to="/registration">Register Now </Link></p>
            </div>
        </div>

    )
}
