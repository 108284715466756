import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar';
import axios from 'axios';
import Dashboardheader from './Dashboardheader';
import baseUrl from './CONSTANT';
function TanRegistrationView() {
    const [tanview, setTanView] = useState([])

    const getData = (() => {
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.get(`${baseUrl}/adminuser/tan-registration`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setTanView(res.data.tan_registration)
            })
            .catch((error) => {
                console.log(error)
            })
    })
    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Dashboardheader />
            <div className='d-flex'>
                <div>
                    <Sidebar />
                </div>
                <div className='container p-4' style={{height:"100vh"}}>
                    <div className='card-border'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Email Id </h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        {tanview.email_id}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Pan Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        <img src={`${baseUrl}${tanview.pan}`} style={{ width: '200px' }} alt='pan number' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Phone Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        {tanview.phone_number}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Aadhar Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        <img src={`https://api.maallelo.com${tanview.aadhar}`} style={{ width: '200px' }} alt='aadhar' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default TanRegistrationView
