import React, { useEffect, useState } from 'react'
import axios from "axios";
import Sidebar from './Sidebar';
import Dashboardheader from './Dashboardheader';
import men from "./Image/man.webp";
import { Button, Modal } from 'react-bootstrap';
import baseUrl from './CONSTANT';

export default function Profile() {
    const [message, setMessage] = useState([]);
    const [color, setColor] = useState();
    const [show, setShow] = useState(false);
    const [ifullName, setFullName] = useState('');
    const [iphoneNumber, setPhoneNumber] = useState('');
    const [iemail, setEmail] = useState('');
    const [igender, setGender] = useState('');
    const [istate, setState] = useState('');
    const [idistrict, setDistrict] = useState('');
    const [iblock, setBlock] = useState('');
    const [ipanchayat, setPanchayat] = useState('');

    const getData = () => {
        const token = JSON.parse(localStorage.getItem('token'))
        axios.get(`${baseUrl}/accounts/profile`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                setFullName(res.data.profile.full_name)
                setPhoneNumber(res.data.profile.phone_number)
                setGender(res.data.profile.gender)
                setEmail(res.data.profile.email)
                setState(res.data.profile.state)
                setDistrict(res.data.profile.district)
                setBlock(res.data.profile.block)
                setPanchayat(res.data.profile.panchayat)
                console.log(res.data.profile)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getData()
    }, [])

    const handleShow = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        getData()
    };

    function handleSubmit(e) {
        e.preventDefault()
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        const full_name = ifullName;
        const gender = igender;
        const phone_number = iphoneNumber;
        const email = iemail;
        const state = istate;
        const district = idistrict;
        const panchayat = ipanchayat;
        const block = iblock;

        axios.post(`${baseUrl}/accounts/updateprofile`
            , { full_name, phone_number, gender, state, district, panchayat, email, block }
            , { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setMessage(response.data.message)
                if (response.data.status === 200) {
                    getData()
                    setColor("green");
                } else {
                    setColor("")
                }
                setTimeout(() => {
                    setMessage("")
                }, 2000)
                console.log(response)

            })
            .catch((error) => {
                console.log(error)
            })

    }

    return (
        <>
            <Dashboardheader />
            <div className='d-flex'>
                <div>
                    <Sidebar />
                </div>
                <div className='container'>
                    <div className='row mt-1'>
                        <div className='col-md-4 card shadow-lg p-3 mb-5 bg-body rounded' style={{ height: '450px' }}>
                            <img src={men} className="card-img-top" alt="Profile" />
                            <h5 className='text-center'>{ifullName}</h5>
                        </div>
                        <div className='col-md-7 ms-2 card shadow-lg p-3 mb-5 bg-body rounded'>
                            <div style={{ backgroundColor: `${color}`, color: 'white', width: '160px' }}>{message}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Full Name</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{ifullName}</p>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Gender</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{igender}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Email</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{iemail}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Phone</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{iphoneNumber}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">State</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{istate}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">District</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{idistrict}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Block</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{iblock}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Panchayat</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{ipanchayat}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className='text-center'>
                                    <Button variant="primary" className='fs-4 text' onClick={handleShow} style={{
                                        width: "100%", fontWeight: 'bold',
                                        letterSpacing: '1px'
                                    }}>
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ----------------------------------model box code------------------------------------------- */}

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Change</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label for="name" className="form-label text-black">Name</label>
                                <input type="name" name="full_name" value={ifullName} onChange={(e) => { setFullName(e.target.value) }} className="form-control" id="name" />
                            </div>
                            <div className="mb-3">
                                <label for="email" className="form-label text-black">Email</label>
                                <input type="email" name="email" value={iemail} onChange={(e) => { setEmail(e.target.value) }} className="form-control" id="email" />
                            </div>
                            <hr />
                            <div className="mb-3">
                                <label for="gender" className="form-label text-black">Gender</label>
                                <input className="form-check-input text-black" value={igender} onChange={(e) => { setGender(e.target.value) }} type="radio" name="gender" id="male" />
                                <span className='text-black'>Male</span>
                                <input className="form-check-input" type="radio" onChange={(e) => { setGender(e.target.value) }} value={igender} name="gender" id="Female" />
                                <span className="text-black">Female</span>
                            </div>
                            <hr />
                            <div className="mb-3">
                                <label for="phone" className="form-label text-black">Phone Number</label>
                                <input type="phone" name="phone_number" value={iphoneNumber} onChange={(e) => { setPhoneNumber(e.target.value)}} className="form-control" id="phone" />
                            </div>
                            <div className="mb-3">
                                <label for="state" className="form-label text-black">State</label>
                                <input type="text" name="state" value={istate} onChange={(e) => { setState(e.target.value) }} className="form-control" id="state" />
                            </div>
                            <div className="mb-3">
                                <label for="district" className="form-label text-black">District</label>
                                <input type="text" name="district" value={idistrict} onChange={(e) => { setDistrict(e.target.value) }} className="form-control" id="district" />
                            </div>
                            <div className="mb-3">
                                <label for="block" className="form-label text-black">Block</label>
                                <input type="text" name="block" value={iblock} onChange={(e) => {setBlock(e.target.value) }} className="form-control" id="block" />
                            </div>
                            <div className="mb-3">
                                <label for="panchayat" className="form-label text-black">Panchayat</label>
                                <input type="text" name="panchayat" value={ipanchayat} onChange={(e) => { setPanchayat(e.target.value) }} className="form-control" id="panchayat" />
                            </div>
                            <Button type='submit' variant="primary" onClick={handleClose}>
                                Save Changes
                            </Button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>





        </>
    )
}
