import React,{useState,useEffect} from 'react'
import Sidebar from './Sidebar';
import Dashboardheader from './Dashboardheader';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import baseUrl from './CONSTANT';

function Page3() {
    const [tan, setTan] = useState([])
    const [gstuid, setGstuuid] = useState([])
    const [message,setMessage]=useState();
    const [color, setColor] = useState();
    const navigate = useNavigate()
    
    function handleEdit() {
        navigate('/tan-registration')
    }

    function handleView() {
        navigate('/tan-view')
    }
    const handleApply = (() => {
        const uid = gstuid
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/apply-tan-reg`,{uid}, { headers: { "Authorization": `Bearer ${token}` } })
        .then((res)=>{
            console.log(res)
            setMessage(res.data.message)
            if (res.data.status === 200) {
                setColor("green");
                setTimeout(() => {
                    setMessage('')
                    setColor("");
                },5000)
            } else {
                setColor("red")
                console.log('red')
            }
        })
        .catch((error)=>{
            console.log(error)
        })

    })
    const getData = (() => {
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.get(`${baseUrl}/adminuser/tan-registration`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setTan(res.data.tan_registration) 
                setGstuuid(res.data.tan_registration.uid)        
            })
            .catch((error) => {
                console.log(error)
            })
    })


    useEffect(()=>{
      getData()
    },[])
    return (
        <>
        <Dashboardheader />
            <div className='d-flex'>
                <div>
                    <Sidebar />
                </div>
                <div className='container-fluid'>
                    <div className='card-border'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="row pt-4 pb-4 border">
                                    <h4 className="text-center d-inline" style={{ backgroundColor: `${color}`, color: 'white' }}>{message}</h4> 
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Email Id </h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        {
                                                            tan.email_id==="/media/noData"?
                                                                <h6>Not UPLOADED</h6> :
                                                                <h6> UPLOADED</h6>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 border-left">
                                            <p>Email ID of&nbsp; Authorised offer&nbsp; for GST Registration ( Panchayat Sachiv / Accountant / Or any other )</p>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Mobile Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        {
                                                            tan.phone_number==="/media/noData"? 
                                                                <h6>Not UPLOADED</h6> :
                                                                <h6> UPLOADED</h6>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 border-left">
                                            <p>Mobile No.&nbsp; of&nbsp; Authorised offer&nbsp; for GST Registration ( Panchayat Sachiv / Accountant / Or any other )</p>
                                        </div>
                                    </div>
                                    
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Aadhar Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        {
                                                            tan.aadhar==="/media/noData"?
                                                                <h6>Not UPLOADED</h6> :
                                                                <h6> UPLOADED</h6>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 border-left">
                                            <p>AADHAR&nbsp; ID&nbsp;&nbsp;OF&nbsp; DDO/ Tax Deductor / Authorised Signatory</p>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Pan Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">

                                                        {
                                                        tan.pan=== "/media/noData" ?
                                                                <h6>Not UPLOADED</h6> :
                                                                <h6> UPLOADED</h6>
                                                        }</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 border-left">
                                            <p>PAN OF&nbsp; DDO/ Tax Deductor / Authorised Signatory</p>
                                        </div>
                                    </div>
                                    <div>
                                        <button type='submit' className="btn btn-success button-2" onClick={handleEdit}>EDIT</button>
                                        <button type='submit' className="btn btn-success button-2" onClick={handleView}>VIEW</button>
                                        <button type='submit' className="btn btn-success button-2" onClick={handleApply}>APPLY</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Page3
