import React from 'react'
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';

function Sidebar() {
  return (
    <>
      <div style={{ display: 'flex', height: '100%', overflow: 'scroll initial' }}>
        <CDBSidebar textColor="#fff" backgroundColor="#333">
          <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
            <a href="/dashboard" className="text-decoration-none" style={{
              color: 'inherit', fontWeight: 'bold',
              letterSpacing: '1px'
            }}>
              User Panel
            </a>
          </CDBSidebarHeader>
          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <NavLink exact to="/dashboard" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="columns" style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px'
                }}>Dashboard</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/profile-user" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="user" style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px'
                }}>Profile page</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/status" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="chart-line" style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px'
                }}>Status</CDBSidebarMenuItem>
              </NavLink>

            </CDBSidebarMenu>
          </CDBSidebarContent>
        </CDBSidebar>
      </div>
    </>
  )
}

export default Sidebar
