import React from 'react'
import { NavLink } from 'react-router-dom'

function Error() {
  return (
    <div className='text-center mt-5'>
      <h1 style={{fontFamily:"math",fontSize:"61px"}}>404 Error Page</h1>
      <p style={{fontFamily:"monospace",fontSize:"25px"}}>Sorry This page doesn't exist</p>
      <NavLink to="/" style={{color:"black",fontSize:"25px",fontFamily:"monospace"}}>Go Back </NavLink>
    </div>
  )
}

export default Error
