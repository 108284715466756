import React from 'react'
import { useNavigate } from 'react-router-dom';
import image from ".//Image/GST PANCHAYAT LOGO FINAL 2.png";

function Dashboardheader() {
  const navigate = useNavigate()
  function handleLogOut() {
    localStorage.removeItem('token')
    navigate('/')

  }
  return (
    <div className='container-fluid sticky-top'>
      <div className='row'>
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow sticky-top">
          <div style={{ width: '100px', height: '80px' }}>
            <a href="/dashboard">
              <img src={image} alt="this is logo" />
            </a>
          </div>
          <button type="button" className="button-2" onClick={handleLogOut}><span>Log Out</span></button>
        </header>
      </div>
    </div>
  )
}

export default Dashboardheader
