import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar';
import Dashboardheader from './Dashboardheader';
import baseUrl from './CONSTANT';
import axios from 'axios';

function Status() {
    const [gststatus, setGststatus] = useState([])
    const [tanstatus, setTanstatus] = useState([])
    const [returnstatus, setReturnstatus] = useState([])
    const [message, setMessage] = useState([]);
    const [color, setColor] = useState();



    const getGst = (() => {
        const token = JSON.parse(localStorage.getItem('token'))
        axios.get(`${baseUrl}/adminuser/status-gst-registration`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setGststatus(res.data.status_data)
            })
            .catch((error) => {
                console.log(error)
            })

    })

    const getTan = (() => {
        const token = JSON.parse(localStorage.getItem('token'))
        axios.get(`${baseUrl}/adminuser/status-tan-reg`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setTanstatus(res.data.status_data)
            })
            .catch((error) => {
                console.log(error)
            })

    })

    const getReturn = (() => {
        const token = JSON.parse(localStorage.getItem('token'))
        axios.get(`${baseUrl}/adminuser/status-gst-return`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setReturnstatus(res.data.status_data)
            })
            .catch((error) => {
                console.log(error)
            })

    })

    useEffect(() => {
        getGst()
        getTan()
        getReturn()
    }, [])

    const submitOTP = ((e) => {
        e.preventDefault()
        const uid = e.target.uid.value;
        const otp = e.target.otp.value;
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/gstregshareotp`, { uid, otp }, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setMessage(res.data.message)
                if (res.data.status === 200) {
                    setColor("green");
                    setTimeout(() => {
                        setMessage("")
                        setColor("")
                    }, 2000);
                } else {
                    setColor("red")
                }

            })
            .catch((error) => {
                console.log(error)
            })
    })

    const submitRetOtp = ((e) => {
        e.preventDefault()
        const uid = e.target.uid.value;
        const otp = e.target.otp.value;
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/gstretshareotp`, { uid, otp }, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setMessage(res.data.message)
                if (res.data.status === 200) {
                    setColor("green");
                    setTimeout(() => {
                        setMessage("")
                        setColor("")
                    }, 2000);
                } else {
                    setColor("red")
                }

            })
            .catch((error) => {
                console.log(error)
            })
    })

    const submitTanOtp = ((e) => {
        e.preventDefault()
        const uid = e.target.uid.value;
        const otp = e.target.otp.value;
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/tanregshareotp`, { uid, otp }, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setMessage(res.data.message)
                if (res.data.status === 200) {
                    setColor("green");
                    setTimeout(() => {
                        setMessage("")
                        setColor("")
                    }, 2000);
                } else {
                    setColor("red")
                }
            })
            .catch((error) => {
                console.log(error)
            })
    })

    return (
        <>
            <Dashboardheader />
            <div className='d-flex'>
                <div>
                    <Sidebar />
                </div>
                <div className='container-fluid' style={{ height: "103vh" }}>
                    {/* <div className="card w-100"> */}
                    <div className="card-body">
                        <div style={{ height: "100vh" }}>
                            <div className="table-responsive scrollable mt-2 ps-container ps-theme-default">
                                <div>
                                    <h4 className="message-handle" style={{ backgroundColor: `${color}`, color: 'white', fontSize: "0.9rem" }}>{message}</h4>
                                </div>
                                <table className="table v-middle">
                                    <thead>
                                        <tr>
                                            <th className="border-top-0">Service Name</th>
                                            <th className="border-top-0">Task Status</th>
                                            <th className="border-top-0">Message Status</th>
                                            <th className="border-top-0">Applied Date</th>
                                            <th className="border-top-0">Share OTP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {gststatus.map((items, index) => (
                                            <tr key={index}>
                                                <td>GST Registration - </td>
                                                <td>
                                                    <span className="badge bg-success badge-pill ms-1">{items.status}</span>
                                                </td>
                                                <td>
                                                    <span className="badge bg-danger text-white">{items.notes}</span>
                                                </td>
                                                <td>{items.updated_at}</td>
                                                <td>
                                                    <form onSubmit={submitOTP}>
                                                        <input type="hidden" name='uid' value={items.uid}></input>
                                                        <input type="text" name="otp"  style={{ width: "100px" }}></input>
                                                        <button type='sumbit' className="btn btn-success mx-2" >SUBMIT</button>
                                                    </form>
                                                </td>
                                            </tr>
                                        ))}
                                        {tanstatus.map((item, index) => (
                                            <tr key={index}>
                                                <td>TAN Registration - </td>
                                                <td>
                                                    <span className="badge bg-success badge-pill ms-1">{item.status}</span>
                                                </td>
                                                <td>
                                                    <span className="badge bg-danger text-white">{item.notes}</span>
                                                </td>
                                                <td>{item.updated_at}</td>
                                                <td>
                                                    <form onSubmit={submitTanOtp}>
                                                        <input type="hidden" name='uid' value={item.uid}></input>
                                                        <input type="text" name="otp"  style={{ width: "100px" }}></input>
                                                        <button type='sumbit' className="btn btn-success mx-2" >SUBMIT</button>
                                                    </form>
                                                </td>
                                            </tr>
                                        ))}

                                        {returnstatus.map((data, index) => (
                                            <tr key={index}>
                                                <td>RETURN Filling - </td>
                                                <td>
                                                    <span className="badge bg-success badge-pill ms-1">{data.status}</span>
                                                </td>
                                                <td>
                                                    <span className="badge bg-danger text-white">{data.notes}</span>
                                                </td>
                                                <td>{data.updated_at}</td> 
                                                <td>
                                                    <form onSubmit={submitRetOtp}>
                                                        <input type="hidden" name='uid' value={data.uid}></input>
                                                        <input type="text" name="otp"  style={{ width: "100px" }}></input>
                                                        <button type='sumbit' className="btn btn-success mx-2" >SUBMIT</button>
                                                    </form>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="ps-scrollbar-x-rail" style={{ left: '0px', bottom: '0px' }}>
                                    <div className="ps-scrollbar-x" tabindex="0" style={{ left: '0px', width: '0px' }}>
                                    </div>
                                </div>
                                <div className="ps-scrollbar-y-rail" style={{ top: '0px', right: '3px' }}>
                                    <div className="ps-scrollbar-y" tabindex="0" style={{ top: '0px', height: '0px' }}></div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Status
