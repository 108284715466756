import React, { useState,useEffect } from 'react'
import Dashboardheader from './Dashboardheader'
import Sidebar from './Sidebar'
import axios from 'axios'
import baseUrl from './CONSTANT'

function GstRegistrationView() {
    const[gstview,setGstView]=useState([])

    const getData = (() => {
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.get(`${baseUrl}/adminuser/gst-registration`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                   setGstView(res.data.gst_registration)   
            })
            .catch((error) => {
                console.log(error)
            })
    })
    useEffect(()=>{
  getData()
    },[])

    return (
        <>
            <Dashboardheader />
            <div className='d-flex'>
                <div>
                    <Sidebar />
                </div>
                <div className='container p-4' style={{height:"160vh"}}>
                    <div className='card-border'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">TAN </h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                    <img src={`${baseUrl}${gstview.tan}`} style={{width:'200px'}} alt='tan'/> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Email Id </h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                    {gstview.email_id}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Address </h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="mb-0">
                                                   <img src={`${baseUrl}${gstview.panchyat_address_proof}`} style={{width:'150px'}} alt='address'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Pan Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                    <img src={`${baseUrl}${gstview.pan}`} style={{width:'200px'}} alt='pan number'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Aadhar Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                    <img src={`${baseUrl}${gstview.aadhar}`} style={{width:'200px'}} alt='aadhar'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Phone Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        {gstview.phone_number}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default GstRegistrationView
