import React from 'react'
import reg from "./Image/reg.png";
import Return from "./Image/return.png";
import tan from "./Image/TAN.png";
import { useNavigate } from "react-router-dom"



export default function Dashboardcontent() {
    const navigate = useNavigate()

    function gstRegistration() {
        navigate('/gst-detail')
    }

    function tanRegistration() {
        navigate('/tan-detail')
    }
    function gstReturnFilling() {
        navigate('/returnfilling-detail')
    }

    return (
        <div style={{ height: "100vh" }}>
            <div className='container-fluid shadow-lg bg-white rounded'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div style={{ width: "200px" }}>
                            <img src={reg} className="card-img-top ms-2" alt="Gst registration" />
                            <div className="card-body text-center  p-4">
                                <button type="submit" onClick={gstRegistration} className="myButton p-2">GST Registration</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div style={{ width: "200px" }}>
                            <img src={Return} className="card-img-top ms-2" style={{ width: "100%" }} alt="Gst Return filling" />
                            <div className="card-body text-center  p-4">
                                <button type="submit" className="myButton p-2" onClick={gstReturnFilling}>GST <br />Return Filling</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div style={{ width: "200px" }}>
                            <img src={tan} className="card-img-top ms-2" alt="Tan Registration" />
                            <div className="card-body text-center p-4">
                                <button type="submit" className="myButton p-2" onClick={tanRegistration}>Tan Registration </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-5'>
                    <div className='mt-3'>
                        <h4 style={{color:"red"}}>Create Challan </h4>
                        <h4 style={{color:"red"}}>Track Payment Status </h4>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='mt-4' >
                        <h5><a href='https://payment.gst.gov.in/payment/' style={{textDecoration:"none"}}>Click Here </a></h5>
                        <h5><a href='https://payment.gst.gov.in/payment/trackpayment/' style={{textDecoration:"none"}}>Click Here </a> </h5>
                    </div>
                </div>
            </div>
        </div>




    )
}
