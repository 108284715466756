import { useState } from 'react';
import axios from "axios";
import image from ".//Image/GST PANCHAYAT LOGO FINAL 2.png";
import gst from "./Image/gst.webp";
import { useNavigate } from 'react-router-dom';
import baseUrl from './CONSTANT';
export default function Registration() {
    const navigate = useNavigate()
    const [message, setMessage] = useState();
    const [color, setColor] = useState();

    function submitHandler(e) {
        e.preventDefault()
        console.log('submit button clicked')
        const full_name = e.target.full_name.value;
        const gender = e.target.gender.value;
        const phone_number = e.target.phone_number.value;
        const email = e.target.email.value;
        const state = e.target.state.value;
        const district = e.target.district.value;
        const panchayat = e.target.panchayat.value;
        const block = e.target.block.value;
        const password = "Rahul!2233";

        axios.post(`${baseUrl}/accounts/register/`, {
            full_name, phone_number, gender, state, district, panchayat, email, block, password
        })
            .then((response) => {
                console.log(response)
                setMessage(response.data.message)
                if (response.data.status === 200) {
                    setColor("green");
                    console.log('green')
                    setTimeout(() => {
                       navigate('/')
                    },3000);
                }else {
                    setColor("red")
                    console.log('red')
                };
                if (response.data.status === 400) {
                    setColor("red");
                    console.log('red')
                    setTimeout(() => {
                        setMessage('')
                        setColor("")
                    },3000);
                }else {
                    setColor("green")
                    console.log('green')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };


    return (
          <div  className="reg-img" style={{ backgroundImage: `url(${gst})` }}>
        <div className="container">
            <div className="row" >
                <div className="col-md-6" style={{}}>
                    
                </div>
                <div className="col-md-5 offset-md-1 p-4">
                    <div className='form-wrapper'>
                        <div className='img-container'>
                            <img className="logo-form" src={image} alt="this is logo" />
                        </div>
                        <h1 className='title'>User Registration</h1>
                        <form onSubmit={submitHandler}>
                            <label htmlFor>Full Name:</label>
                            <input className="input" type="text" name="full_name" placeholder="Full name" size="15" required />
                            <br></br><br></br>
                            <label htmlFor="gender">
                                Gender : &nbsp;&nbsp;&nbsp;
                            </label>
                            <select name="gender" id="gender" className="input me-4" required>
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                          
                            <br></br> <br></br>
                            <label htmlFor='phone_number'>Phone No :</label>
                            <input className="input" type="tel" name="phone_number" id='phone_number' size="15" placeholder='Enter number' pattern="[1-9]{1}[0-9]{9}" required /> <br></br><br></br>
                            <label htmlFor="email">
                                Email :
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input className="input" type="email" id="email" name="email" size="15" placeholder='Email' required />
                            <br></br>
                            <br></br>
                            <label htmlFor="state">
                                State :
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input className="input" type="state" id="state" name="state" size="15" placeholder='State' required />
                            <br></br>
                            <br></br>
                            <label htmlFor="district">
                                District :
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input className="input" type="district" id="district" name="district" size="15" placeholder='District' required />
                            <br></br>
                            <br></br>
                            <label htmlFor="block">
                                Block :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </label>
                            <input className="input" type="block" id="block" name="block" size="15" placeholder='Block' required />
                            
                            <br></br>
                            <br></br>
                            <label htmlFor="panchayat">
                                Panchayat :
                            </label>
                            <input className="input" type="panchayat" id="panchayat" name="panchayat" size="15" placeholder='Panchayat' required />
                            <br></br><br></br>
                            <button className='btn-registration' type="submit">Register</button>
                            <h1 className="message-handle" style={{ backgroundColor: `${color}`, color: 'white' }}>{message}</h1>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </div>


    )
}
