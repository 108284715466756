
import './App.css';
import Login from './Component/Login';
import Registration from "./Component/Registration";
import { Route, Routes } from "react-router-dom";
import GstRegistration from './Component/GstRegistration';
import TanRegistration from './Component/TanRegistration';
import DashboardScreen from './Component/DashboardScreen';
import Profile from './Component/Profile';
import Page from './Component/Page';
import Page3 from './Component/Page3';
import Page2 from './Component/Page2';
import ReturnFilling from './Component/ReturnFilling';
import GstRegistrationView from './Component/GstRegistrationView'
import ReturnView from './Component/ReturnView';
import TanRegistrationView from './Component/TanRegistrationView';
import Status from './Component/Status';
import Error from './Component/Error';


function App() {
  return (
    <div className="App">
      <Routes> 
      <Route exact path="/" element={<Login/>} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/gst-registration" element={<GstRegistration/>} />
        <Route path="/profile-user" element={<Profile/>} />
        <Route path="/dashboard" element={<DashboardScreen/>} />
        <Route path="/gst-detail" element={<Page/>} />
        <Route path="/tan-registration" element={<TanRegistration/>} />
        <Route path="/tan-detail" element={<Page3/>} />
        <Route path="/returnfilling-registration" element={<ReturnFilling/>} />
        <Route path="/returnfilling-detail" element={<Page2/>} />
        <Route path="/gst-view" element={<GstRegistrationView/>} />
        <Route path="/return-view" element={<ReturnView/>} />
        <Route path="/tan-view" element={<TanRegistrationView/>} />
        <Route path="/status" element={<Status/>} />
        <Route path="*" element={<Error/>} />
      </Routes> 
    </div>
  );
}

export default App;
