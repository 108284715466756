import { useEffect, useState } from 'react'
import React from 'react'
import Dashboardheader from './Dashboardheader'
import Sidebar from './Sidebar'
import axios from 'axios'
import baseUrl from './CONSTANT'

function ReturnView() {
    const [returnview, setReturnView] = useState([])

    const getData = (() => {
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.get(`${baseUrl}/adminuser/gst-return`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                console.log(res.data.email_id)
                setReturnView(res.data.gst_return)
            })
            .catch((error) => {
                console.log(error)
            })
    })
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <Dashboardheader />
            <div className='d-flex'>
                <div>
                    <Sidebar />
                </div>
                <div className='container p-4' style={{height:"100vh"}}>
                    <div className='card-border'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Email Id </h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        {returnview.email_id}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Pan Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        <img src={`${baseUrl}${returnview.pan}`} style={{ width: '200px' }} alt='pan number' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Phone Number</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        {returnview.phone_number}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Payment Detail</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        <img src={`${baseUrl}${returnview.payment_detail}`} style={{ width: '200px' }} alt='payment detail' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4 border">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h6 className="pb-2">Salary Slip</h6>
                                                </div>
                                                <div className="col-md-4 pb-2">
                                                    <div className="text-muted mb-0">
                                                        <img src={`${baseUrl}${returnview.sale_invoice}`} style={{ width: '200px' }} alt='salary slip' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReturnView
