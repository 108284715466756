import React from 'react'
import Dashboardcontent from './Dashboardcontent';
import Dashboardheader from './Dashboardheader';
import Sidebar from './Sidebar';


function DashboardScreen() {
    return (
        <>
            <Dashboardheader />
            <div className='d-flex'>
                <div>
                    <Sidebar />
                </div>
                <div className=' m-auto d-flex justify-content-center mt-5'>
                    <Dashboardcontent/>
                </div>
            </div>



        </>

    )
}

export default DashboardScreen
