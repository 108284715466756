import React, { useState } from 'react'
import Sidebar from './Sidebar';
import Dashboardheader from './Dashboardheader';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import baseUrl from './CONSTANT';


function TanRegistration() {
    const navigate = useNavigate
    const [filepan, setFilePan] = useState()
    const [fileaadhar, setFileAadhar] = useState()
    const [message, setMessage] = useState()
    const [color, setColor] = useState()

    const handlePan = e => {
        console.log(e.target.files[0])
        setFilePan(e.target.files[0])
    }
    const handleAadhar = e => {
        setFileAadhar(e.target.files[0]);
    };

    const handleSubmit = ((e) => {
        const token = JSON.parse(localStorage.getItem('token'))
        e.preventDefault()
        const formdata = new FormData();
        formdata.append('email', e.target.email_id.value)
        formdata.append('phone_number', e.target.phone_number.value)
        formdata.append('pan', filepan)
        formdata.append('aadhar', fileaadhar)

        axios.post(`${baseUrl}/adminuser/tan-registration`, formdata,
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'accept': 'application/json',
                    'content-type': 'multipart/form-data',
                }
            }
        )
            .then((res) => {
                console.log(res)
                setMessage(res.data.message)
                if (res.data.status === 200) {
                    setColor("green");
                    console.log('green')
                    setTimeout(() => {
                        navigate("/tan-detail")
                    }, 3000)
                } else {
                    setColor("red")
                    console.log('red')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    })
    return (
        <>
            <Dashboardheader />
            <div className='d-flex'>
                <div>
                    <Sidebar />
                </div>
                <div>
                    <form onSubmit={handleSubmit}>
                        <h2 className='p-4'> Upload Documents | TAN Registration</h2>
                        <div className="container-fluid">
                            <div className="row pt-shadow-lg p-3 mb-5 bg-white rounded">
                                <div className="col-md-12">
                                    <div className="card border shadow-lg p-3 mb-5 bg-body-tertiary rounded">
                                        <div className="card-body ">
                                            <div className="row ">
                                                <div className="col-md-12">
                                                    <div className="row pt-4 pb-4 border">
                                                        <div className="col-md-8">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <h6 className="pb-2">PAN OF Signatory / DDO </h6>
                                                                </div>
                                                                <div className="col-md-4 pb-2">
                                                                    <input type="file" className="form-control " name="pan" onChange={handlePan} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 border-left">
                                                            <p>PAN copy of  Authorised Signatory / DDO / Accountant / Panchayat Sachiv </p>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4 pb-4 border">
                                                        <div className="col-md-8">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <h6 className="pb-2">AADHAR of Signatory </h6>
                                                                </div>
                                                                <div className="col-md-4 pb-2">
                                                                    <input type="file" className="form-control" name="aadhar" onChange={handleAadhar} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 border-left">
                                                            <p>AADHAR OF Authorised Signatory / DDO/ Accountant / Panchayat Sachiv </p>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4 pb-4 border">
                                                        <div className="col-md-8">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <h6 className="pb-2">Email id</h6>
                                                                </div>
                                                                <div className="col-md-4 pb-2">
                                                                    <input type="text" className="form-control" name="email_id" placeholder="Enter Email Id  " required fdprocessedid="zyq6y8" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 border-left">
                                                            <p>Email ID of&nbsp; Authorised offer&nbsp; for GST Registration ( Panchayat Sachiv / Accountant / Or any other )</p>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4 pb-4 border">
                                                        <div className="col-md-8">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <h6 className="pb-2">Mobile No. </h6>
                                                                </div>
                                                                <div className="col-md-4 pb-2">
                                                                    <input type="text" className="form-control " name="phone_number" placeholder="Enter Mobile No." required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 border-left">
                                                            <p>Mobile No.&nbsp; of&nbsp; Authorised offer&nbsp; for GST Registration ( Panchayat Sachiv / Accountant / Or any other )</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button type='submit' className="btn btn-success button-2">Submit</button>
                                                    </div>
                                                    <h4 className="text-center m-auto" style={{ backgroundColor: `${color}`, color: 'white',width:'50%'}}>{message}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}

export default TanRegistration
